import React from 'react';
import cn from 'classnames';
import Markup from 'components/Markup';
import { CollapseEl } from 'landing/ui';
import Article from './Article';
import * as styles from './Collapse.module.scss';

export default function Collapse({
  id,
  title,
  articles,
  theme,
  current,
  isSmallWidth,
  lastBlockList,
  clickHandler,
}) {
  const isOpened = current === id;

  return (
    <section id={id} className={cn(styles.wrapper, theme && styles[theme])}>
      <button
        className={cn(styles.panel, isOpened && styles.active)}
        onClick={() => clickHandler(id, current)}
      >
        <Markup tag="p" className={styles.title}>
          {title}
        </Markup>
      </button>
      <CollapseEl
        toggle={isOpened}
        className={cn(
          styles.panelContentWrapper,
          styles.collapse,
          isSmallWidth && styles.isSmallWidth,
          isOpened && styles.isOpenedWrapper,
          lastBlockList & isOpened && styles.lastBlockList,
        )}
      >
        {articles.map((article, i) => (
          <Article key={`${title}-${i}`} {...article} />
        ))}
      </CollapseEl>
    </section>
  );
}
