import React, { useState, useCallback } from 'react';
import { Container, Title } from 'landing/ui';
import Collapse from './Collapse';
import Article from './Article';

import * as styles from './InfoAccordion.module.scss';

const DEFAULT_OPENED_INDEX = 0;

function InfoAccordion({ title, notice, accordionInfo, description, isSmallWidth }) {
  const [current, setCurrent] = useState(DEFAULT_OPENED_INDEX);

  const collapseClickHandler = useCallback((next, curr) => {
    let delay = 0;

    if (next > curr) {
      const [btn] = document.getElementById(curr).childNodes;
      const { top, height } = btn.getBoundingClientRect();

      delay = 300;

      window.scrollTo({
        top: window.pageYOffset + top + height * (next - curr - 2),
        behavior: 'smooth',
      });
    } else if (next === curr) {
      const [btn] = document.getElementById(0).childNodes;
      const { top, height } = btn.getBoundingClientRect();

      window.scrollTo({
        top: window.pageYOffset + top - height * 4,
        behavior: 'smooth',
      });
    }

    setTimeout(() => {
      if (next === curr) {
        setCurrent(DEFAULT_OPENED_INDEX);
      } else {
        setCurrent(next);
      }
    }, delay);
  }, []);

  return (
    <Container>
      <div className={styles.layout}>
        {title && (
          <Title tag="h2" className={styles.title}>
            {title}
          </Title>
        )}
        {notice && <p className={styles.notice}>{notice}</p>}
        {description &&
          description?.map((article, i) => (
            <Article key={`article-${i}`} {...article} isDescription />
          ))}
        {accordionInfo.map(({ title, articles, lastBlockList }, i) => (
          <Collapse
            key={i}
            id={i}
            title={title}
            articles={articles}
            current={current}
            isSmallWidth={isSmallWidth}
            lastBlockList={lastBlockList}
            clickHandler={collapseClickHandler}
          />
        ))}
      </div>
    </Container>
  );
}

export default InfoAccordion;
