import React from 'react';
import cn from 'classnames';
import * as styles from './Table.module.scss';

export default function Table({ className, rows }) {
  return (
    <section className={className}>
      {rows.map(({ type, cells }, i) => (
        <div key={`${type}-${i}`} className={cn(styles.row, styles[type])}>
          {cells.map((cell, i) => (
            <div key={`${cell}-${i}`} className={styles.cell}>
              {cell}
            </div>
          ))}
        </div>
      ))}
    </section>
  );
}
