// extracted by mini-css-extract-plugin
export var active = "Collapse-module--active--1f7ab";
export var box = "Collapse-module--box--3d533";
export var collapse = "Collapse-module--collapse--59cd8";
export var description = "Collapse-module--description--e922b";
export var isOpenedWrapper = "Collapse-module--isOpenedWrapper--700ee";
export var isSmallWidth = "Collapse-module--isSmallWidth--c8da6";
export var lastBlockList = "Collapse-module--lastBlockList--1c4f0";
export var panel = "Collapse-module--panel--ad6d6";
export var panelContentWrapper = "Collapse-module--panelContentWrapper--efac4";
export var title = "Collapse-module--title--ceaeb";
export var withMarginBottom = "Collapse-module--withMarginBottom--d78dd";
export var wrapper = "Collapse-module--wrapper--9f6b0";